import req from '../utils/request.js'
const path = '/api/mall'
/* 获取集团授权列表 */
export function getBlocList(params) {
  return req.post(`${path}/manage/info/list`, params)
}

/* 修改授权列表 */
export function insertBlocList(params) {
  return req.post(`${path}/manage/info/create`, params)
}

/* 修改授权列表 */
export function updateBlocList(params) {
  return req.post(`${path}/manage/info/update`, params)
}

/* 修改授权列表状态 */
export function updateBlocListState(params) {
  return req.fromPost(`${path}/manage/info/status`, params)
}

/* 获取商品分类列表 */
export function getBlocClassifyList(params) {
  return req.fromPost(`${path}/manage/category/list`, params)
}

/* 修改商品分类列表状态 */
export function getBlocClassifyState(params) {
  return req.fromPost(`${path}/manage/category/status`, params)
}

/* 编辑商品分类列表 */
export function editBlocClassify(params) {
  return req.post(`${path}/manage/category/update`, params)
}

/* 新增商品分类 */
export function insertBlocClassify(params) {
  return req.post(`${path}/manage/category/insert`, params)
}

/* 删除商品分类 */
export function deleteBlocClassify(params) {
  return req.fromPost(`${path}/manage/category/delete`, params)
}
